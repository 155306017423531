import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEventContext } from "../../../contexts/EventContextProvider";
import { useAuthContext } from "../../../contexts/AuthContextProvider";
import { ThreeDots } from "react-loader-spinner";
import { IoIosArrowBack, IoMdArrowRoundBack } from "react-icons/io";
import { MdDelete } from "react-icons/md";
import { baseUrl } from "../../../apis/base_url";

function EditExtraImages({ onUpdate }) {
  const [existingImages, setExistingImages] = useState([]);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [removedImageIds, setRemovedImageIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const {id} = useParams();

  const { singleEventId, editExtraImages,removeSingleExtraImage } = useEventContext();

  useEffect(() => {
    if (singleEventId?.extra_images) {
      setExistingImages(singleEventId.extra_images);
    }
  }, [singleEventId]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setUploadedImages([...uploadedImages, ...files]);
  };

  const handleRemoveExistingImage = async (imageId) => {
    await removeSingleExtraImage({event_image_id:imageId})
    setRemovedImageIds([...removedImageIds, imageId]);
    setExistingImages(existingImages.filter((image) => image.event_image_id !== imageId));
  };

  const handleRemoveNewImage = (index) => {
    setUploadedImages(uploadedImages.filter((_, i) => i !== index));
  };

  const handleUpdateButtonClick = async () => {
    setLoading(true);
    setError("");
  
    // if (!addEventId) {
    //   setError("Event ID is missing. Please try again.");
    //   setLoading(false);
    //   return;
    // }
  
    if (existingImages.length === 0 && uploadedImages.length === 0) {
      setError("Please upload or keep at least one image.");
      setLoading(false);
      return;
    }
  
    let data = new FormData();
    data.append("event_id", singleEventId.event.id);
  
    // Prepare `event_image_id[]` for both existing and new images
    const allImageIds = [];
  
    // Add IDs for existing images (exclude removed ones)
    existingImages
      .filter((image) => !removedImageIds.includes(image.id))
      .forEach((image) => {
        allImageIds.push(image.id);
      });
    uploadedImages.forEach(() => {
      allImageIds.push(null); 
    });
  
    // Append `event_image_id[]`
    allImageIds.forEach((id) => {
      data.append("event_image_id[]", id);
    });
  
    // Append new image files
    uploadedImages.forEach((file, index) => {
      data.append(`extra_image[${index}]`, file);
    });
  
    try {
      const response = await editExtraImages(data);
  
      if (!response || response.success === false) {
        const message =
          response?.error?.message || "Failed to update images. Please try again.";
        setError(message);
      } else{
        setUploadedImages([]);
        navigate(-1)
      }
    } catch (err) {
      setError("An error occurred while updating images. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <section className="w-full p-4 rounded-md">
      <section className="w-full px-10 pt-5 pb-10 rounded-md bg-white">
        {/* <div className="flex space-x-3">
          <IoMdArrowRoundBack size={25} onClick={() => navigate(-1)} className="cursor-pointer" />
          <h1 className="font-semibold text-md">Edit Extra Images</h1>
        </div> */}
        <div className='flex gap-3 items-center mb-4'>
          <button
            onClick={() => navigate("/event/view/"+id)}
            className=" left-0 flex  items-center justify-center p-2 bg-white rounded-full shadow-md w-min h-min"
          >
            <IoIosArrowBack
              size={28}
              className="text-black cursor-pointer"
            />
          </button>
          <h1 className='font-semibold text-2xl'>Edit Extra Images</h1>
        </div>

        <div className="flex flex-col space-y-6 mt-5">
          <div>
            <h2 className="font-medium text-xl mb-2">Existing Images:</h2>
            <div className="flex flex-wrap gap-4">
              {existingImages.map((image) => (
                <div key={image.id} className="relative">
                  <img
                    src={`${baseUrl}/${image.path}/${image.name}`}
                    alt={image.file_name}
                    className="w-40 h-40 object-cover rounded-md border"
                  />
                  <button
                    onClick={() => handleRemoveExistingImage(image.event_image_id)}
                    className="absolute top-0 right-0 text-white text-xs rounded-full px-2 py-1"
                  >
                    <MdDelete size={20} color="red" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col">
            <div className="relative border rounded-md border-black px-2 py-4 w-full">
              <input
                type="file"
                name="extra_images[]"
                id="extra_images"
                multiple
                onChange={handleFileChange}
                className="w-full p-0 border-0 text-base font-normal focus:outline-none focus:ring-0 focus:border-0 focus:bg-white"
              />
              <p className="absolute -top-3 left-5 bg-white px-2">Upload Extra Images</p>
            </div>

            <div className="mt-4 flex flex-wrap gap-4">
              {uploadedImages.map((file, index) => (
                <div key={index} className="relative">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={file.name}
                    className="w-20 h-20 object-cover rounded-md border"
                  />
                  <button
                    onClick={() => handleRemoveNewImage(index)}
                    className="absolute top-0 right-0  text-white text-xs rounded-full px-2 py-1"
                  >
                    <MdDelete size={20} color="red" />
                  </button>
                </div>
              ))}
            </div>
          </div>

          {error && <p className="text-sm font-normal text-center text-red-500">{error}</p>}

          <div className="flex items-center justify-end w-full space-x-8">
            <Link to="/all-events">
              <button className="border px-16 py-2 rounded-md border-black text-[#8DC67B] text-md font-semibold">
                Cancel
              </button>
            </Link>
            <button
              onClick={handleUpdateButtonClick}
              className="px-16 py-2 rounded-md text-white bg-[#8DC67B] text-md font-semibold"
            >
              {loading ? (
                <ThreeDots color="#ffffff" height={20} width={40} className="py-2" />
              ) : (
                "Update"
              )}
            </button>
          </div>
        </div>
      </section>
    </section>
  );
}

export default EditExtraImages;
