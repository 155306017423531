import React, { useEffect, useState } from 'react';
import { RxAvatar } from "react-icons/rx";
import { useAuthContext } from '../../contexts/AuthContextProvider';

const EditProfile = () => {
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, error, setError, userProfile, editAdminProfile } = useAuthContext();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confPassword:'',
    rememberMe: false,
    profileImage: null,
  });

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () => setShowConfPassword(!showConfPassword);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
        setFormData((prev) => ({
            ...prev,
            profileImage: file, // Ensure this is the File object
        }));

        // const reader = new FileReader();
        // reader.onload = (e) => {
        //     setSelectedImage(e.target.result);
        // };
        // reader.readAsDataURL(file);
    }
};

  const handleInputChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    const newValue = type === 'checkbox' ? checked : type === 'file' ? files[0] : value;

    setFormData((prevData) => ({
      ...prevData,
      [name]: newValue,
    }));
  };

  const handleSaveEdit = async () => {
    setError('');
    setLoading(true);
  
    if (!formData.email || !formData.name) {
      setError('Name and Email are required');
      setLoading(false);
      return;
    }
  
    if (formData.password !== formData.confPassword) {
      setError("Password and Confirm Password should match");
      setLoading(false);
      return;
    }
  
    const payload = new FormData();
    payload.append('name', formData.name);
    payload.append('email', formData.email);
    // payload.append('rememberMe', formData.rememberMe); 
    if (formData.password) payload.append('password', formData.password);
    if (formData.confPassword) payload.append('password_confirmation', formData.confPassword);
    if (formData.profileImage) payload.append('avatar', formData.profileImage);
  
    // Convert FormData to an object (optional for debugging purposes)
    const payloadObject = Object.fromEntries(payload.entries());
    console.log('Payload Object:', payloadObject);
  
    try {
      // Call your API here with the payload
      // Example:
      await editAdminProfile(payload);
  
      setLoading(false);
      console.log('Profile updated successfully');
    } catch (error) {
      setError('Failed to update profile');
      setLoading(false);
    }
  };
  
  

  useEffect(()=>{
    if(userProfile){
      setFormData({
        name:userProfile?.name,
        email:userProfile?.email,
        profileImage:userProfile?.avater
      })
    }
  },[userProfile])

  return (
    <section className="relative min-h-screen">
      <div className="flex items-center pt-16 justify-center h-full w-full">
        <div className="w-full md:w-[30rem] rounded-3xl md:px-2 py-2 z-30">
          <div className="w-full p-3 space-y-5">
            <h2 className="text-[28px] mb-2 font-semibold text-center">Edit Profile</h2>

            <div className="w-full flex justify-center mb-5">
              {
                userProfile?.avater ? <img className='w-40 rounded' src={userProfile?.avater} alt='Image'/>:
              <RxAvatar size={100} />
              }
            </div>

            {/* Name */}
            <div className="w-full">
              <label htmlFor="name" className="block text-sm font-normal text-gray-500">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Enter Name"
                className="mt-1 px-3 py-3 w-full rounded-md text-base font-normal border border-gray-300 focus:outline-none focus:ring focus:border-green-400"
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>

            {/* Email */}
            <div className="w-full">
              <label htmlFor="email" className="block text-sm font-normal text-gray-500">
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Enter Email"
                className="mt-1 px-3 py-3 w-full rounded-md text-base font-normal border border-gray-300 focus:outline-none focus:ring focus:border-green-400"
                value={formData.email}
                onChange={handleInputChange}
              />
            </div>

            {/* Password */}
            <div>
              <label htmlFor="password" className="block text-sm font-normal text-gray-500">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  name="password"
                  placeholder="Enter New Password"
                  className="mt-1 px-3 py-3 w-full border border-gray-300 focus:outline-none focus:ring focus:border-green-400 rounded-md text-base font-normal"
                  value={formData.password}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  onClick={togglePasswordVisibility}
                  className="absolute inset-y-0 right-0 px-3 py-2 mt-1 text-gray-400 hover:text-gray-600"
                >
                  <img
                    src={showPassword ? '/images/auth/eye.svg' : '/images/auth/hide_eye.svg'}
                    alt="Toggle Password Visibility"
                  />
                </button>
              </div>
            </div>

            {/* confirm Password */}
            <div>
              <label htmlFor="password" className="block text-sm font-normal text-gray-500">
                Confirm Password
              </label>
              <div className="relative">
                <input
                  type={showConfPassword ? 'text' : 'password'}
                  id="confPassword"
                  name="confPassword"
                  placeholder="Confirm Password"
                  className="mt-1 px-3 py-3 w-full border border-gray-300 focus:outline-none focus:ring focus:border-green-400 rounded-md text-base font-normal"
                  value={formData.confPassword}
                  onChange={handleInputChange}
                />
                <button
                  type="button"
                  onClick={toggleConfirmPasswordVisibility}
                  className="absolute inset-y-0 right-0 px-3 py-2 mt-1 text-gray-400 hover:text-gray-600"
                >
                  <img
                    src={showConfPassword ? '/images/auth/eye.svg' : '/images/auth/hide_eye.svg'}
                    alt="Toggle Password Visibility"
                  />
                </button>
              </div>
            </div>

            {/* Profile Image */}
            <div className="w-full">
              <label htmlFor="profileImage" className="block text-sm font-normal text-gray-500">
               Update profile Image
              </label>
              <input
                type="file"
                id="profileImage"
                name="profileImage"
                className="mt-1 px-3 py-3 w-full rounded-md text-base font-normal border border-gray-300 focus:outline-none focus:ring focus:border-green-400"
                onChange={handleImageChange}
              />
            </div>

            {error && (
              <p className="text-sm font-normal text-red-500 text-center">{error}</p>
            )}

            <div className="py-5">
              <button
                type="button"
                onClick={handleSaveEdit}
                className="bg-black text-white w-full py-3 mb-4 rounded-md text-base font-medium"
              >
                {loading ? 'Saving...' : 'Save Edit'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditProfile;